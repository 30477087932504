import styled, {css, createGlobalStyle} from "styled-components";
import normalize from "styled-normalize";
import {motion} from "framer-motion";
import { Link } from "gatsby";

export const theme = {
  black: '#000000',
  white: '#ffffff',
  primary: '#1AD9B3',
  primaryHover: '#14A689',
  gray900: '#666666',
  gray600: '#B2B2B2',
  breakpoints: {
    starter: '0px',
    xs: '320px',
    phone: '425px',
    tablet: '768px',
    laptop: '992px',
    laptopL: '1200px',
    desktop: '1440px',
    desktopL: '1920px',
  },
};

export const GlobalStyles = createGlobalStyle`
  
  ${normalize}
  
  *,
  ::after,
  ::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }
  
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }
  
  body {
    font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: ${props => props.theme.white};
    line-height: 1.65;
    font-size: 0.875rem;
    overscroll-behavior: none;
    overflow-x: hidden;
    overflow: auto/scroll;
    max-width: 100%;
  }
  
  ul {
    list-style: none;
  }
  
  p {
    font-size: clamp(0.875rem, 5vw, 1.125rem);
    line-height: clamp(1.5rem, 5vw, 2rem);
    margin-bottom: 1.25rem;
    color: ${props => props.theme.gray900}
  }
  
  li {
    font-size: clamp(0.875rem, 5vw, 1.125rem);
    line-height: clamp(1.5rem, 5vw, 2rem);
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Gilroy';
    font-weight: 600;
    letter-spacing: -1px;
  }
  
  h1 {
    font-size: clamp(2.5rem, 5vw, 3.5rem);
    line-height: clamp(3.5rem, 5vw, 4.5rem);
    margin: 0;
  }
  
   h2 {
    font-size: clamp(2rem, 5vw, 3rem);
    line-height: clamp(3rem, 5vw, 4rem);
  }
  
  h3 {
    font-size: clamp(1.5rem, 5vw, 2.5rem);
    line-height: clamp(2.5rem, 5vw, 3.5rem);
  }
  
  h4 {
    font-size: clamp(1.25rem, 5vw, 2rem);
    line-height: clamp(2rem, 5vw, 3rem);
  }
  
  h5 {
    font-size: clamp(1.125rem, 5vw, 1.5rem);
    line-height: clamp(2rem, 5vw, 2.5rem);
  }
  
  h6 {
    font-size: clamp(1rem, 5vw, 1.25rem);
    line-height: clamp(1.5rem, 5vw, 2rem);
  }
  
  // h1 {
  //   font-size: clamp(35px, 5vw, 56px);
  //   line-height: 72px;
  //   margin: 0 0 1rem 0;
  //   max-width: 100%;
  //   letter-spacing: -0.03em;
  //  
  // @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
  //   font-size: clamp(35px, 6vw, 64px);
  // }
  //
  // @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
  // font-size: clamp(35px, 6vw, 64px);
  // }
  //
  // @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
  // font-size: clamp(35px, 6vw, 72px);
  // }
  //
  // }
  //
  // h2 {
  //   margin-bottom: 1.5rem;
  //   margin-top: 2.5rem;
  //   font-size: clamp(30px, 2vw, 34px);
  //   line-height: 1.3;
  //
  //   @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
  //     font-size: clamp(34px, 2vw, 46px);
  //   }
  //   @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
  //     font-size: clamp(38px, 2vw, 48px);
  //   }
  // }
  //
  // h3 {
  //   font-size: clamp(16px, 3vw, 21px);
  //   margin-bottom: 1rem;
  //   margin-top: 1.5rem;
  // }
  
  .gatsby-image-wrapper-constrained {
    display: flex;
  }
`;

export const ReactLink = styled(motion(Link))`
    -webkit-tap-highlight-color: transparent;
    color: ${props => props.theme.white};
    z-index: 999;
`;

export const Ahref = styled(motion.a)`
    -webkit-tap-highlight-color: transparent;
`;

export const Container = styled(motion.div)`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        padding: 0 54px;
    }
  
  ${props => props.fluid &&
    css`
      padding: 0;
      margin: 0;
      max-width: 100% !important;
    `}
  
  ${props => props.alignCenter &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

export const ContentWrap = styled.div`
  flex: 1; 
`;

export const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: #1b6d85;
  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `};
  ${props =>
    props.flexEnd &&
    css`
      justify-content: flex-end;
    `};
  ${props =>
    props.alignTop &&
    css`
      align-items: flex-start;
    `};
  ${props =>
    props.alignCenter &&
    css`
      align-items: center;
    `};
  ${props =>
    props.noHeight &&
    css`
      height: 0;
    `};
`;

export const Button = styled(motion(Link))`
  display: inline-block;
  background: ${props => props.theme.primary};
  white-space: nowrap;
  padding: 0.75rem 2rem;
  color: ${props => props.theme.white};
  font-size: clamp(0.875rem, 5vw, 1.125rem);
  line-height: clamp(1.5rem, 5vw, 2rem);
  font-weight: 600;
  outline: none;
  border: none;
  min-width: 50px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  // If framer motion is used delete this
  transition: 80ms !important;
  
  &:hover {
  background: ${props => props.theme.primaryHover};
  transform: translateY(-2px);
  }
  
  ${props => props.centered && css`
    display: block;
    text-align: center;
    margin: 0 auto;
    min-width: 50px;
  `}
  
  ${props => props.secondary && css`
    background: ${props => props.theme.secondary};
    
    &:hover {
      background: ${props => props.theme.secondaryDarken};
      transform: translateY(-2px);
    }
  `}
`;

export const ButtonA = styled(motion.a)`
  display: inline-block;
  background: ${props => props.theme.primary};
  white-space: nowrap;
  padding: ${({big}) => (big ? '12px 21px' : '10px 18px')};
  color: ${props => props.theme.white};
  text-transform: uppercase;
  font-size: ${({big}) => (big ? '18px' : '16px')};
  letter-spacing: 1.2px;
  line-height: 1.65;
  font-weight: 700;
  outline: none;
  border: none;
  min-width: 50px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  // If framer motion is used delete this
  transition: 80ms !important;
  
  &:hover {
  background: ${props => props.theme.primaryDarken};
  transform: translateY(-2px);
  }
  
  ${props => props.centered && css`
    display: block;
    text-align: center;
    margin: 0 auto;
    min-width: 50px;
  `}
  
  ${props => props.secondary && css`
    background: ${props => props.theme.secondary};
    
    &:hover {
      background: ${props => props.theme.secondaryDarken};
      transform: translateY(-2px);
    }
  `}
  
  @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    padding: ${({big}) => (big ? '14px 18px' : '12px 16px')};
    font-size: ${({big}) => (big ? '16px' : '14px')};
  }
`;

export const ButtonSubmit = styled(motion.button)`
  display: inline-block;
  background: ${props => props.theme.primary};
  white-space: nowrap;
  padding: 0.75rem 2rem;
  color: ${props => props.theme.white};
  font-size: clamp(0.875rem, 5vw, 1.125rem);
  line-height: clamp(1.5rem, 5vw, 2rem);
  font-weight: 600;
  outline: none;
  border: none;
  min-width: 50px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  // If framer motion is used delete this
  transition: 80ms !important;
  
  &:hover {
  background: ${props => props.theme.primaryHover};
  transform: translateY(-2px);
  }
  
  ${props => props.centered && css`
    display: block;
    text-align: center;
    margin: 0 auto;
    min-width: 50px;
  `}
  
  ${props => props.secondary && css`
    background: ${props => props.theme.secondary};
    
    &:hover {
      background: ${props => props.theme.secondaryDarken};
      transform: translateY(-2px);
    }
  `}
`;

export const PrimaryDot = styled.span`
  color: ${props => props.theme.primary}
`;

export const PrimaryColor = styled.span`
  color: ${props => props.theme.primary}
`;

