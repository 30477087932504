import styled from 'styled-components';

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 144px;
  width: 100%;
  //position: absolute;
  //top: 0;
  //right: 0;
  //left: 0;
  z-index: 999;
  max-width: 1920px;
  padding: 0 20px;
  
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        top: 54px;
    }
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        padding: 0 54px;
    }

`;

export const NavList = styled.ul`
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
`;

export const NavListItem = styled.li`
    text-decoration: none;
    margin-left: 10px;
    list-style: none;
    
    :focus {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
      color: black;
    }
    
    a {
     position:relative;
     z-index: 1;
     text-transform: uppercase;
     color: ${props => props.theme.white};
     font-weight: 600;
     padding: 0.5rem 0rem;
     text-decoration: none;
     transition: 100ms;
     
     :hover {
      color: ${props => props.theme.primary};
     }
     
    }
`;

export const HeaderH4 = styled.h4`
  font-size: clamp(2rem, 5vw, 2rem);
`;