import {createGlobalStyle} from "styled-components";

// Gilroy Heavy
import GilroyHeavyEot from './Gilroy/Gilroy-Heavy.eot';
import GilroyHeavyWoff2 from './Gilroy/Gilroy-Heavy.woff2';
import GilroyHeavyWoff from './Gilroy/Gilroy-Heavy.woff';
import GilroyHeavyTtf from './Gilroy/Gilroy-Heavy.ttf';

// Gilroy Heavy Italic
import GilroyHeavyItalicEot from './Gilroy/Gilroy-HeavyItalic.eot';
import GilroyHeavyItalicWoff2 from './Gilroy/Gilroy-HeavyItalic.woff2';
import GilroyHeavyItalicWoff from './Gilroy/Gilroy-HeavyItalic.woff';
import GilroyHeavyItalicTtf from './Gilroy/Gilroy-HeavyItalic.ttf';

// Gilroy Black
import GilroyBlackEot from './Gilroy/Gilroy-Black.eot';
import GilroyBlackWoff2 from './Gilroy/Gilroy-Black.woff2';
import GilroyBlackWoff from './Gilroy/Gilroy-Black.woff';
import GilroyBlackTtf from './Gilroy/Gilroy-Black.ttf';

// Gilroy BlackItalic
import GilroyBlackItalicEot from './Gilroy/Gilroy-BlackItalic.eot';
import GilroyBlackItalicWoff2 from './Gilroy/Gilroy-BlackItalic.woff2';
import GilroyBlackItalicWoff from './Gilroy/Gilroy-BlackItalic.woff';
import GilroyBlackItalicTtf from './Gilroy/Gilroy-BlackItalic.ttf';

// Gilroy Bold
import GilroyBoldEot from './Gilroy/Gilroy-Bold.eot';
import GilroyBoldWoff2 from './Gilroy/Gilroy-Bold.woff2';
import GilroyBoldWoff from './Gilroy/Gilroy-Bold.woff';
import GilroyBoldTtf from './Gilroy/Gilroy-Bold.ttf';

// Gilroy BoldItalic
import GilroyBoldItalicEot from './Gilroy/Gilroy-BoldItalic.eot';
import GilroyBoldItalicWoff2 from './Gilroy/Gilroy-BoldItalic.woff2';
import GilroyBoldItalicWoff from './Gilroy/Gilroy-BoldItalic.woff';
import GilroyBoldItalicTtf from './Gilroy/Gilroy-BoldItalic.ttf';

// Gilroy SemiBold
import GilroySemiBoldEot from './Gilroy/Gilroy-SemiBold.eot';
import GilroySemiBoldWoff2 from './Gilroy/Gilroy-SemiBold.woff2';
import GilroySemiBoldWoff from './Gilroy/Gilroy-SemiBold.woff';
import GilroySemiBoldTtf from './Gilroy/Gilroy-SemiBold.ttf';

// Gilroy SemiBoldItalic
import GilroySemiBoldItalicEot from './Gilroy/Gilroy-SemiBoldItalic.eot';
import GilroySemiBoldItalicWoff2 from './Gilroy/Gilroy-SemiBoldItalic.woff2';
import GilroySemiBoldItalicWoff from './Gilroy/Gilroy-SemiBoldItalic.woff';
import GilroySemiBoldItalicTtf from './Gilroy/Gilroy-SemiBoldItalic.ttf';

// Gilroy Medium
import GilroyMediumEot from './Gilroy/Gilroy-Medium.eot';
import GilroyMediumWoff2 from './Gilroy/Gilroy-Medium.woff2';
import GilroyMediumWoff from './Gilroy/Gilroy-Medium.woff';
import GilroyMediumTtf from './Gilroy/Gilroy-Medium.ttf';

// Gilroy SemiBoldItalic
import GilroyMediumItalicEot from './Gilroy/Gilroy-MediumItalic.eot';
import GilroyMediumItalicWoff2 from './Gilroy/Gilroy-MediumItalic.woff2';
import GilroyMediumItalicWoff from './Gilroy/Gilroy-MediumItalic.woff';
import GilroyMediumItalicTtf from './Gilroy/Gilroy-MediumItalic.ttf';

// Gilroy Regular
import GilroyRegularEot from './Gilroy/Gilroy-Regular.eot';
import GilroyRegularWoff2 from './Gilroy/Gilroy-Regular.woff2';
import GilroyRegularWoff from './Gilroy/Gilroy-Regular.woff';
import GilroyRegularTtf from './Gilroy/Gilroy-Regular.ttf';

// Gilroy RegularItalic
import GilroyRegularItalicEot from './Gilroy/Gilroy-RegularItalic.eot';
import GilroyRegularItalicWoff2 from './Gilroy/Gilroy-RegularItalic.woff2';
import GilroyRegularItalicWoff from './Gilroy/Gilroy-RegularItalic.woff';
import GilroyRegularItalicTtf from './Gilroy/Gilroy-RegularItalic.ttf';

export const GlobalFonts = createGlobalStyle`

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyHeavyEot});
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
        url(${GilroyHeavyEot}) format('embedded-opentype'),
        url(${GilroyHeavyWoff2}) format('woff2'),
        url(${GilroyHeavyWoff}) format('woff'),
        url(${GilroyHeavyTtf}) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyHeavyItalicEot});
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
        url(${GilroyHeavyItalicEot}) format('embedded-opentype'),
        url(${GilroyHeavyItalicWoff2}) format('woff2'),
        url(${GilroyHeavyItalicWoff}) format('woff'),
        url(${GilroyHeavyItalicTtf}) format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBlackEot});
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url(${GilroyBlackEot}) format('embedded-opentype'),
        url(${GilroyBlackWoff2}) format('woff2'),
        url(${GilroyBlackWoff}) format('woff'),
        url(${GilroyBlackTtf}) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBlackItalicEot});
    src: local('Gilroy BlackItalic'), local('Gilroy-BlackItalic'),
        url(${GilroyBlackItalicEot}) format('embedded-opentype'),
        url(${GilroyBlackItalicWoff2}) format('woff2'),
        url(${GilroyBlackItalicWoff}) format('woff'),
        url(${GilroyBlackItalicTtf}) format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBoldEot});
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url(${GilroyBoldEot}) format('embedded-opentype'),
        url(${GilroyBoldWoff2}) format('woff2'),
        url(${GilroyBoldWoff}) format('woff'),
        url(${GilroyBoldTtf}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBoldItalicEot});
    src: local('Gilroy BoldItalic'), local('Gilroy-BoldItalic'),
        url(${GilroyBoldItalicEot}) format('embedded-opentype'),
        url(${GilroyBoldItalicWoff2}) format('woff2'),
        url(${GilroyBoldItalicWoff}) format('woff'),
        url(${GilroyBoldItalicTtf}) format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'Gilroy';
    src: url(${GilroySemiBoldEot});
    src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
        url(${GilroySemiBoldEot}) format('embedded-opentype'),
        url(${GilroySemiBoldWoff2}) format('woff2'),
        url(${GilroySemiBoldWoff}) format('woff'),
        url(${GilroySemiBoldTtf}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'Gilroy';
    src: url(${GilroySemiBoldItalicEot});
    src: local('Gilroy SemiBoldItalic'), local('Gilroy-SemiBoldItalic'),
        url(${GilroySemiBoldItalicEot}) format('embedded-opentype'),
        url(${GilroySemiBoldItalicWoff2}) format('woff2'),
        url(${GilroySemiBoldItalicWoff}) format('woff'),
        url(${GilroySemiBoldItalicTtf}) format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    }
    
       @font-face {
    font-family: 'Gilroy';
    src: url(${GilroyMediumEot});
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url(${GilroyMediumEot}) format('embedded-opentype'),
        url(${GilroyMediumWoff2}) format('woff2'),
        url(${GilroyMediumWoff}) format('woff'),
        url(${GilroyMediumTtf}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'Gilroy';
    src: url(${GilroyMediumItalicEot});
    src: local('Gilroy MediumItalic'), local('Gilroy-MediumItalic'),
        url(${GilroyMediumItalicEot}) format('embedded-opentype'),
        url(${GilroyMediumItalicWoff2}) format('woff2'),
        url(${GilroyMediumItalicWoff}) format('woff'),
        url(${GilroyMediumItalicTtf}) format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    }

    @font-face {
        font-family: 'Gilroy';
        src: url(${GilroyRegularEot});
        src: local('Gilroy Regular'), local('Gilroy-Regular'),
            url(${GilroyRegularEot}) format('embedded-opentype'),
            url(${GilroyRegularWoff2}) format('woff2'),
            url(${GilroyRegularWoff}) format('woff'),
            url(${GilroyRegularTtf}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Gilroy';
        src: url(${GilroyRegularItalicEot});
        src: local('Gilroy RegularItalic'), local('Gilroy-RegularItalic'),
            url(${GilroyRegularItalicEot}) format('embedded-opentype'),
            url(${GilroyRegularItalicWoff2}) format('woff2'),
            url(${GilroyRegularItalicWoff}) format('woff'),
            url(${GilroyRegularItalicTtf}) format('truetype');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }
`;