import styled from "styled-components"
import {motion} from "framer-motion";
import BackgroundImage from "../../assets/images/background-image.jpg"

export const HeroContainer = styled(motion.div)`
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //height: 100vh;
    //height: calc(var(--vh, 1vh)*100);
    background: ${props => props.theme.black};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    
    padding-top: 144px;
    
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.5;
        background-image: url(${BackgroundImage});
        height: 100%;
        width: 100%;
        background-size: cover;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: center center;
    }
`;

export const HeroWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    column-gap: 20px;
    grid-auto-flow: column;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 5rem;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        margin-top: 2rem;
    }
`;

export const HeroH1 = styled(motion.h1)`
  color: ${props => props.theme.white};
  margin-bottom: 24px;
`;

export const HeroH3 = styled(motion.h3)`
  color: ${props => props.theme.white};
  margin-bottom: 2rem;
`;

export const HeroH6 = styled(motion.a)`
  color: ${props => props.theme.white};
  font-size: clamp(1rem, 5vw, 1.25rem);
  line-height: clamp(1.5rem, 5vw, 2rem);
  font-weight: 600;
  white-space: nowrap;
`;

export const HeroP = styled(motion.p)`
  color: ${props => props.theme.gray600};
  font-size: clamp(0.875rem, 5vw, 1.125rem);
  line-height: clamp(1.75rem, 5vw, 2rem);
  margin: 0 0 3rem 0;
  max-width: 600px;
`;

export const DisplayH2 = styled.h2`
  font-size: clamp(3rem, 5vw, 4rem);
  line-height: clamp(4rem, 5vw, 5rem);
  color: ${props => props.theme.primary};
`;

export const HeroWrapperOne = styled (motion.div)`
 grid-column: 1/13;
 align-self: center;
 margin-bottom: 4rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        grid-column: 1/7;
        margin-bottom: 0rem;
    }
 
`;

export const HeroWrapperTwo = styled (motion.div)`
    grid-column: 1/13;
    align-self: center;
    padding: 3rem 2rem;
    border-radius: 8px;
    position: relative;
    z-index: 1;
   
   &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.black};
    border-radius: 8px;
    z-index: -1;
    opacity: 0.7;
   }
   
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        grid-column: 7/13;
        padding: 4rem 3rem;
    }
`;

export const HeroContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6.5rem;
    @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
        flex-direction: row;
    }
`;

export const HeroContactItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2.5rem;
  margin-bottom: 0.75rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
        flex-direction: row;
        margin-bottom: 0;
    }
  
  svg {
    margin-right: 0.75rem;
    transform: translateY(-2px);
  }
`;







