import React from "react"
import Header from "../Header/Header"
import { GlobalStyles, theme, PageWrapper, ContentWrap } from "./Layout.styles";
import {ThemeProvider} from "styled-components";
import {GlobalFonts} from "../../assets/fonts/GlobalFonts";

class Layout extends React.Component {
    componentDidMount () {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    render () {
        const {children} = this.props;
        return (
            <ThemeProvider theme={theme}>
                <GlobalStyles/>
                <GlobalFonts/>
                <PageWrapper>
                    <ContentWrap>
                        <Header />
                        <main>{children}</main>
                    </ContentWrap>
                </PageWrapper>
            </ThemeProvider>
        )
    }
}

export default Layout

