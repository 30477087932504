import React from "react"
import {ReactLink, theme, PrimaryDot} from "../Layout/Layout.styles";
import {Navigation, NavList, NavListItem, HeaderH4} from "./Header.styles"
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next';

const Header = () => {

    const {changeLanguage} = useI18next();
    const {t} = useTranslation();

    return (
        <Navigation>
            <ReactLink to="/" initial="hidden" animate="visible" exit="exit">
                <HeaderH4>{t("DomainName")}<PrimaryDot>.</PrimaryDot>com</HeaderH4>
            </ReactLink>


            <NavList>
                <NavListItem>
                    <ReactLink
                        to="/lt/"
                        activeStyle={{color: theme.gray600, pointerEvents: 'none'}}
                        onClick={(e) => {
                            e.preventDefault();
                            changeLanguage('lt');
                        }}>
                        lt
                    </ReactLink>
                </NavListItem>
                <NavListItem>
                    <ReactLink
                        to="/"
                        activeStyle={{color: theme.gray600, pointerEvents: 'none'}}
                        onClick={(e) => {
                            e.preventDefault();
                            changeLanguage('en');
                        }}>
                        en
                    </ReactLink>
                </NavListItem>
            </NavList>
        </Navigation>
  )
};

export default Header
